
import { Icon } from "vant";
import { Decrypt } from "../../scripts/utils";
import moment from "moment";
import apiSchedule from "@/api/schedule";

export default {
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      scheduleList: [],
      statusTitle: "即将上课",
      curSchedule: {}
    };
  },
  mounted() {
    (this as any).getScheduleList();
  },
  methods: {
    async getScheduleList() {
      const res = (await apiSchedule.getScheduleList({
        UserID: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
        StartTime: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        EndTime: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      })) as any;
      if (res.Result && res.Data) {
        (this as any).scheduleList = JSON.parse(res.Data).Datas;
        let self = this as any;
        const curTime = new Date().getTime();
        const nowDate = moment().format("YYYY-MM-DD");
        try {
          self.scheduleList.forEach((ele: any, key: number) =>{
             const startTime = `${nowDate} ${ele.LessonClsTime}`;//上课
             const sTime = new Date(startTime.replace(/-/g, "/")).getTime();
             const endTime = `${nowDate} ${ele.LessonOutTime}`;//下课
             const eTime = new Date(endTime.replace(/-/g, "/")).getTime();
             if (curTime < eTime) {
              if (curTime > sTime){
                self.statusTitle = "正在上课"
              } else {
                self.statusTitle = "即将上课"
              }
              self.curSchedule = ele;
              throw 'Out';
             }
          })
        } catch (e) {
          if (e !== "Out") throw e
        }
        
      }
    },
    // 判断当前时段课程
    getCurJudgeDate(start:any, end:any, index:number) {
      let self = this as any;
      let curTime = new Date().getTime();
      let nowDate = moment().format("YYYY-MM-DD");
      let startTime = `${nowDate} ${start}`;
      let endTime = `${nowDate} ${end}`;
      let sTime = new Date(startTime).getTime();
      let eTime = new Date(endTime).getTime();
      if (curTime > sTime  && curTime < eTime) {
        self.statusTitle = "正在上课"
        // self.curSchedule = self.scheduleList[index]
      }
      if (curTime < sTime) {
        self.statusTitle = "即将上课"
        // self.curSchedule = self.scheduleList[index]
      }
    }
  },
};
