
import { defineComponent } from "vue";
import { Icon } from "vant";
import { Decrypt } from "../../scripts/utils";
import moment from "moment";
import apiSchedule from "@/api/schedule";

interface WeekDay {
  showDate: string;
  date: string;
  isToday: boolean;
  events: any;
}
export default defineComponent({
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      // headDate: new Date().getTime(),
      title: "",
      theMonth: "",
      weekDays: [] as Date[],
      headDate: new Date() as any,
      weekNames: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      // timeDivide: [
      //   {
      //     lesson: 1,
      //     start: "08:00",
      //     end: "08:45",
      //   },
      //   {
      //     lesson: 2,
      //     start: "08:55",
      //     end: "09:40",
      //   },
      //   {
      //     lesson: 3,
      //     start: "10:00",
      //     end: "10:45",
      //   },
      //   {
      //     lesson: 4,
      //     start: "10:55",
      //     end: "11:40",
      //   },
      //   {
      //     lesson: 5,
      //     start: "14:00",
      //     end: "14:45",
      //   },
      //   {
      //     lesson: 6,
      //     start: "14:55",
      //     end: "15:40",
      //   },
      //   {
      //     lesson: 7,
      //     start: "16:00",
      //     end: "16:45",
      //   },
      //   {
      //     lesson: 8,
      //     start: "16:55",
      //     end: "17:40",
      //   },
      //   {
      //     lesson: 9,
      //     start: "19:00",
      //     end: "19:45",
      //   },
      //   {
      //     lesson: 10,
      //     start: "19:55",
      //     end: "20:40",
      //   },
      //   {
      //     lesson: 11,
      //     start: "21:00",
      //     end: "21:45",
      //   },
      // ],
      timeDivide: [],
      isToday: true,
      theWeekDay: 0,
      weekDate: [] as WeekDay[],
      bgColors: [
        "#9564FA",
        "#58D7A9",
        "#45C8CD",
        "#F77440",
        "#F65151",
        "#2F7DFF",
        "#EC53CA",
        "#6297FE",
        "#ee71d2",
        "#f47272",
      ],
      events: [],
    };
  },
  created() {
    let self = this as any;
    self.GetAllLessons();
    self.loadData();
    self.getWeekDate();
    self.isTheWeekDay();
  },
  methods: {
    async GetAllLessons() {
      let self = this as any;
      const res = (await apiSchedule.GetAllLessons()) as any;
      if (res.Result) {
        self.timeDivide = res.Datas.map((item: any) => {
          return {
            lesson: item.LessonSession,
            start: item.LessonClsTime,
            end: item.LessonOutTime,
          };
        });
      }
    },
    goPrev() {
      let self = this as any;
      const now = new Date();
      self.headDate = self.changeDateRange(self.headDate, -1);
      self.isToday = now.toDateString() === self.headDate.toDateString();
      self.loadData();
    },
    goNext() {
      let self = this as any;
      const now = new Date();
      self.headDate = self.changeDateRange(self.headDate, 1);

      self.isToday = now.toDateString() === self.headDate.toDateString();
      self.loadData();
    },
    isTheWeekDay() {
      let self = this as any;
      let theWeekDay = new Date().getDay();
      if (theWeekDay === 0) {
        theWeekDay = 7;
      }

      self.theWeekDay = theWeekDay;
    },
    changeDateRange(date: any, num: number) {
      const dt = new Date(date);
      return new Date(dt.valueOf() + num * 7 * 24 * 60 * 60 * 1000);
    },
    async getScheduleList() {
      let self = this as any;
      const res = (await apiSchedule.getScheduleList({
        UserID: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
        StartTime: moment(self.weekDays[0]).format("YYYY-MM-DD HH:mm:ss"),
        EndTime: moment(self.weekDays[6]).format("YYYY-MM-DD HH:mm:ss"),
      })) as any;
      if (res.Result && res.Data) {
        self.events = JSON.parse(res.Data).Datas.map((item: any) => {
          return {
            start: item.CSDate + " " + item.LessonClsTime,
            end: item.CSDate + " " + item.LessonOutTime,
            lesson: Number(item.CSLesson),
            course: item.CourseName,
            color: self.bgColors[Math.floor(Math.random() * 10)],
            building: item.CampusesName,
            clsRoom: item.ClsRoomName,
          };
        });
      }
    },
    async loadData() {
      let self = this as any;
      self.weekDays = self.getDates(self.headDate);

      self.title =
        self.format(self.weekDays[0], "MM-dd") +
        `  ——  ` +
        self.format(self.weekDays[6], "MM-dd");

      self.theMonth = self.format(new Date(self.headDate), "M月");

      // 加载课表
      await self.getScheduleList();
      self.getWeekDate();
    },
    // 获取周视图的天元素格式化
    getWeekDate() {
      let self = this as any;
      const oldWeekDays = self.weekDays;
      const newWeekDays: WeekDay[] = [];
      oldWeekDays.forEach((e: any, index: number) => {
        let isToday = false;
        if (new Date().toDateString() === e.toDateString()) {
          isToday = true;
        }
        newWeekDays.push({
          showDate: self.format(e, "MM-dd"),
          date: self.format(e, "yyyy-MM-dd"),
          isToday,
          events: self.slotTimeEvents(e), // 整理事件集合 （拿事件去比较时间，分发事件到时间插槽内）
        });
      });
      self.weekDate = newWeekDays;
    },
    // 发现该时间段所有的事件
    slotTimeEvents(date: any) {
      let self = this as any;
      let thisDayEvents = JSON.parse(JSON.stringify(self.events));
      thisDayEvents = thisDayEvents.filter(
        (event: { start: string | number | Date }) => {
          if (typeof event.start === "string") {
            event.start = new Date(event.start.replace(/-/g, "/"));
          } 
          const day = new Date(event.start);
          // console.log(date.toLocaleDateString(), day.toLocaleDateString());
          return date.toLocaleDateString() === day.toLocaleDateString();
        }
      );

      self.judgeTime(thisDayEvents);

      return thisDayEvents;
    },
    judgeTime(arr: any[]) {
      let self = this as any;
      arr.forEach(
        (event: {
          start: string | number | Date;
          weekDay: string;
          weekDayIndex: number;
        }) => {
          const dayS = new Date(event.start);

          let week = dayS.getDay();
          if (week === 0) {
            week = 7;
          }

          event.weekDay = self.weekNames[Number(week) - 1];
          event.weekDayIndex = week;
        }
      );
    },
    // 获取当前周日期数组
    getDates(date: any) {
      var newDate = new Date(date);
      var timesStamp = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        0,
        0,
        0
      ).getTime();
      // var timesStamp = newDate.getTime();
      var currenDay = newDate.getDay();
      var dates = [];
      for (var i = 0; i < 7; i++) {
        dates.push(
          new Date(
            timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
          )
        );
      }
      return dates;
    },
    format(date: any, format: string) {
      if (typeof date === "string") {
        date = new Date(date.replace(/-/g, "/"));
      } else {
        date = new Date(date);
      }

      var map = {
        y: date.getFullYear() + "",
        M: date.getMonth() + 1 + "",
        d: date.getDate() + "",
        h: date.getHours() + "",
        m: date.getMinutes() + "",
        s: date.getSeconds() + "",
        q: Math.floor((date.getMonth() + 3) / 3) + "",
        S: date.getMilliseconds() + "",
      };

      format = format.replace(
        /([yMdhmsqS])+/g,
        (all: any, t: "y" | "M" | "d" | "h" | "m" | "s" | "q" | "S") => {
          var v = map[t];
          if (v !== undefined) {
            if (all.length > 1) {
              v = "0" + v;
              v = v.substr(v.length - 2);
            }
            return v;
          } else if (t === "y") {
            return String(date.getFullYear()).substr(4 - all.length);
          }
          return all;
        }
      );
      return format;
    },
  },
});
