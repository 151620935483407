import { get } from '@/scripts/http';
import { Decrypt } from "@/scripts/utils";
import { ScheduleParam } from "./type/schedule-type"

const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
export default {
    ServiceURL: `/${ORGTOKEN}/V4/AssetM`,
    //获取课表
    async getScheduleList(data: ScheduleParam) {
        const res = await get(`${this.ServiceURL}/GetMyScheduleByTidAndDate`, data);
        return res;
    },
    //获取课节
    async GetAllLessons() {
        const res = await get(`${this.ServiceURL}/GetAllLessons`, {});
        return res;
    }
}